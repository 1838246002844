<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl class="box">
            <!-- kriterij iskanja -->
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-form id="form_timeline" ref="form" v-model="valid" lazy-validation>
                    <v-card id="form_card">
                        <v-card-title>
                                <span class="subtitle-1"><v-icon left>mdi-filter</v-icon>Časovnica (križne zapore)</span>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card1 = !card1">
                                    <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1">

                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                            <!-- tip časovnice-->
                            <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                <v-layout row justify-center v-bind="columnBinding">
                                    <v-flex xs12 md4 align-content-center>
                                        <v-subheader class="pt-0 pl-0"><strong>Tip urnika</strong></v-subheader>
                                    </v-flex>
                                    <v-flex xs12 md8 align-content-center>
                                        <v-select
                                        v-model="selected_timeline_type"
                                        :items="timeline_types"
                                        label="Izberi tip urnika"
                                        clearable
                                        chips
                                        deletable-chips
                                        prepend-icon="mdi-monitor-star"
                                        item-value="id"
                                        item-text="name"
                                        return-object
                                        dense
                                        no-data-text="Ni podatkov za prikaz"
                                        :rules="selected_timeline_type_rules"
                                    ></v-select>   
                                    </v-flex>
                                </v-layout>
                            </v-container>
                            <div style="min-height: 5px;"></div>

                            <!-- kateri dan -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters" v-if="selected_timeline_type !== null && selected_timeline_type.id == 1">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Dan</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_days"
                                            :items="days"
                                            label="Izberi dan"
                                            multiple
                                            chips
                                            deletable-chips
                                            clearable
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                            :rules="(selected_timeline_type !== null && selected_timeline_type.id == 1) ? selected_days_rules : []"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>


                            <!-- datumska omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters" v-if="selected_timeline_type !== null && selected_timeline_type.id == 2">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Datumska omejitev (od - do)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- začetni datum -->
                                            <v-menu
                                                ref="start_date_menu"
                                                :close-on-content-click="false"
                                                v-model="start_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_start_date_formatted"
                                                        label="Datum od"
                                                        prepend-icon="mdi-calendar-today"
                                                        readonly
                                                        clearable
                                                        :rules="(selected_timeline_type !== null && selected_timeline_type.id == 2) ? restriction_start_date_rules : []"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="startDateMenuSave(restriction_start_date)" v-model="restriction_start_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <!-- končni datum -->
                                            <v-menu
                                                ref="end_date_menu"
                                                :close-on-content-click="false"
                                                v-model="end_date_menu"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_date"
                                                
                                                transition="scale-transition"
                                                offset-y
                                                
                                                min-width="290px"
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-on="on"
                                                        v-model="restriction_end_date_formatted"
                                                        label="Datum do"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        clearable
                                                        :rules="(selected_timeline_type !== null && selected_timeline_type.id == 2) ? restriction_end_date_rules : []"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker color="secondary" @input="endDateMenuSave(restriction_end_date)" v-model="restriction_end_date" no-title scrollable locale="sl-si">
                                                </v-date-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- naprava -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-0 pl-0"><strong>Naprava</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-select
                                            v-model="selected_device"
                                            :items="devices"
                                            label="Izberi napravo"
                                            clearable
                                            chips
                                            deletable-chips
                                            prepend-icon="mdi-monitor-star"
                                            item-value="id"
                                            item-text="name"
                                            return-object
                                            dense
                                            no-data-text="Ni podatkov za prikaz"
                                            :rules="selected_device_rules"
                                        ></v-select>   
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <!-- časovna omejitev -->
                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs8 md4 align-content-center>
                                            <v-subheader class="pt-7 pl-0"><strong>Časovna omejitev (od - do)</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                            <v-menu
                                                ref="start_time_menu"
                                                v-model="start_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_start_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="restriction_start_time"
                                                    label="Začetni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                    :rules="start_time_rules"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="start_time_menu"
                                                v-model="restriction_start_time"
                                                
                                                @click:minute="$refs.start_time_menu.save(restriction_start_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                        <v-flex xs12 md4 align-content-center>
                                                <v-menu
                                                ref="end_time_menu"
                                                v-model="end_time_menu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                :return-value.sync="restriction_end_time"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="restriction_end_time"
                                                    label="Končni čas"
                                                    prepend-icon="mdi-clock-outline"
                                                    readonly
                                                    v-on="on"
                                                    clearable
                                                    :rules="end_time_rules"
                                                ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                color="secondary"
                                                format="24hr"
                                                v-if="end_time_menu"
                                                v-model="restriction_end_time"
                                                
                                                @click:minute="$refs.end_time_menu.save(restriction_end_time)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-6 pl-0"><strong>Status časovnice</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            <v-checkbox class="ti" v-model="timeline_active" :label="`Aktivna: ${checkboxTimelineStatusLabel}`"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-container>

                                <v-container grid-list-xl text-x-center align-center  class="container--filters">
                                    <v-layout row justify-center v-bind="columnBinding">
                                        <v-flex xs12 md4 align-content-center>
                                            <v-subheader class="pt-0 pl-0"><strong>Opomba</strong></v-subheader>
                                        </v-flex>
                                        <v-flex xs12 md8 align-content-center>
                                            
                                            <v-textarea
                                            outlined
                                            name="input-7-4"
                                            label="Opombe"
                                            v-model="note"
                                            counter
                                            required
                                            clearable
                                            ></v-textarea>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                        </v-card-text>

                        <!-- PONEDELJEK -->
                        <!--<div v-if="card1">
                            <v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Ponedeljek</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card3 = ! card3">
                                    <v-icon>{{ card3 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card3"></v-card-text>-->

                            <!-- TOREK -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Torek</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card4 = ! card4">
                                    <v-icon>{{ card4 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card4"></v-card-text>-->

                            <!-- SREDA -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Sreda</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card5 = ! card5">
                                    <v-icon>{{ card5 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card5"></v-card-text>-->

                            <!-- ČETRTEK -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Četrtek</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card6 = ! card6">
                                    <v-icon>{{ card6 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card6"></v-card-text>-->

                            <!-- PETEK -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Petek</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card7 = ! card7">
                                    <v-icon>{{ card7 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card7"></v-card-text>-->

                            <!-- SOBOTA -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Sobota</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card8 = ! card8">
                                    <v-icon>{{ card8 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card8"></v-card-text>-->

                            <!-- NEDELJA -->
                            <!--<v-divider></v-divider>
                            <v-app-bar
                                flat
                                color="grey lighten-4"
                                dense
                                class="elevation-0"
                            >   
                                <v-icon left>mdi-calendar-today</v-icon>
                                <v-toolbar-title class="body-2">Nedelja</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="card9 = ! card9">
                                    <v-icon>{{ card9 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                                </v-btn>
                            </v-app-bar>
                            <v-divider></v-divider>
                            <v-card-text v-if="card9"></v-card-text>

                        </div>-->

                        <v-divider></v-divider>
                            <v-card-text v-show="card1" v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn block outlined style="margin-bottom:15px;" @click="submitForm()" color="success" :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn block outlined style="" @click="clearForm()" color="warning"><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                            <v-card-text v-show="card1" v-else>
                                <v-btn @click="submitForm()" color="success" text :disabled="loading"><v-icon left>mdi-arrow-right</v-icon>{{ btnSubmitFormTitle }}</v-btn>
                                <v-btn @click="clearForm()" color="warning" text><v-icon left>mdi-refresh</v-icon>Prekliči</v-btn>
                            </v-card-text>
                    </v-card>
                    </v-form>
                </v-flex>
            </v-layout>

            <br>
            <turnstile-timelines-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            :boxedLayout="true"
            ></turnstile-timelines-data-table> 

            <data-table-toggle-columns
            :dataTableId="dataTableSettings.id"
            :columns="dataTableSettings.headers"
            dataTableColumnsMenuId="toggle-pg-timelines-list-columns"

            ></data-table-toggle-columns>
        </v-container>
    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate, isMobileDevice, getUserApplicationPermissions, checkPermissions, getAppSettingsFromLocalStorage } from '@/helpers/utilities'
import { addExportToQueue } from '@/helpers/services.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const TurnstileTimelinesDataTable = () => import('@/components/TurnstileTimelinesDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {
    components: {
        FormValidationMessages,
        FormSubmitMessages,
        TurnstileTimelinesDataTable,
        DataTableToggleColumns
    },

    data: () => ({
        valid: true,
        card1: true,
        card2: true,
        card3: true,
        card4: true,
        card5: true, 
        card6: true,
        card7: true,
        card8: true,
        card9: true,
        card10: true,
        loading: false,

        timeline_types: [
            {
                id: 1,
                name: "Dnevni (ponedeljek, torek, ...)"
            },
            {
                id: 2,
                name: "Datumski (od - do)"
            }
        ],

        timeline_active: true,
        note: "",

        restriction_start_time:null,
        restriction_end_time: null,

        restriction_start_date: null,
        restriction_end_date:null,
        restriction_start_date_formatted: null,
        restriction_end_date_formatted: null,
        start_date_menu: false,
        end_date_menu:false,

        start_time_menu:false,
        end_time_menu:false,

        devices: [],
        days: [
            {
                id: 1,
                name: "Ponedeljek"
            },
            {
                id: 2,
                name: "Torek"
            },
            {
                id: 3,
                name: "Sreda"
            },
            {
                id: 4,
                name: "Četrtek"
            },
            {
                id: 5,
                name: "Petek"
            },
            {
                id: 6,
                name: "Sobota"
            },
            {
                id: 7,
                name: "Nedelja"
            }
        ],

        selected_device: null,
        selected_days: [],
        selected_timeline_type: null,

        btnSubmitFormTitle: "Shrani",

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        currentDate: null,

        selected_timeline_type_rules: [v => !!v || 'Polje tip urnika je obvezno'],
        
        selected_days_rules: [
            v => !!v || 'Polje dan je obvezno',
            v => v.length > 0 || 'Polje dan je obvezno'],
        
        selected_device_rules: [ 
            v => !!v || 'Polje naprava je obvezno',
        ],
        
        start_time_rules:  [v => !!v || 'Polje začetni čas je obvezno'],
        end_time_rules:  [v => !!v || 'Polje končni čas je obvezno'],
        
        restriction_start_date_rules:  [v => !!v || 'Polje datum od je obvezno'],
        restriction_end_date_rules:  [v => !!v || 'Polje datum do je obvezno'],

        dataTableSettings: {
            id: 'dtTurnstileTimelines',
            title: 'Seznam časovnic',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 4,
            headers: [
                { text: "ID", value: "id", class: "text-xs-left", visibility: true},
                { text: "Naprava", value: "parking_gate_name", class: "text-xs-left", visibility: true },
                { text: "Začetni čas", value: "restriction_start_time", class: "text-xs-left", visibility: true },
                { text: "Končni čas", value: "restriction_end_time", class: "text-xs-left", visibility: true },
                { text: "Tip urnika", value: "timeline_type_name", class: "text-xs-left", visibility: true },
                { text: "Obdobje", value: "restriction_date", class: "text-xs-left", visibility: true },
                { text: "Status", value: "active", class: "text-xs-left", visibility: true },
                //{ text: "Čas vnosa", value: "updated_at_formatted", class: "text-xs-right", visibility: true },
                { text: "Upravljanje", value: "action", align: "right", class: "text-xs-right", sortable: false, visibility: true},
            ],
            updateInterval: true,
            rowsPerPage: 10,
            endpoint: '',
            editItem: {
                route: null,
                routeParameterValue: 'uuid'
            },
            deleteItem: {
                vuexAction: 'DELETE_TURNSTILE_TIMELINE',
                replaceStrWithColumnValue: 'id',
                confirmationText : "Sta prepričani da želite odstraniti časovnico št. {str_to_replace} ?",
                dialogConfirmSuccessText: 'Časovnica {str_to_replace} je bila uspešno odstranjena.',
                dialogConfirmFailText: 'Pri odstranjevanju časovnice št. {str_to_replace} je prišlo do napake. Poskusite ponovno.'
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh-dt-pg-timelines',
                totalItems : {
                    event: ''
                },
                search: 'dt-pg-timelines-search',
                toggleColumnsId: 'toggle-pg-timelines-list-columns'
            }


        }
    }),

    watch: {
        restriction_start_date (val) {
            this.restriction_start_date_formatted = formatDate(val)
        },

        restriction_end_date (val) {
            this.restriction_end_date_formatted = formatDate(val)
        },

        selected_timeline_type(val) {
            //window.console.log(val)
            if(val !== null) {
                    if(val.id == 1) {
                    this.restriction_start_date = null
                    this.restriction_end_date = null
                } 

                if(val.id == 2) {
                    this.selected_days = []
                }
            }
        },

        card1: {
            handler(value) {
                //window.console.log("Telemetry card1: " + value)
                this.$store.commit("APP_SETTING", {
                    setting: "turnstile_timeline",
                    key: "filter_results_open",
                    value: value
                })
            },

            //immediate: true
        }
    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },

        queryParameters() {
            return {}
        },

        checkboxTimelineStatusLabel() {
            if(this.timeline_active == true) {
                return "Da"
            }

            return "NE"
        }
    },

    methods: {
        submitForm() {
            var vm = this
            if(this.$refs.form.validate()) {

                this.backendValidationMessages = []
                this.backendMessages = []
                this.backendErrorMessages = []

                 vm.$store.commit('SET_PROGRESS', true)
                vm.loading = true
                vm.$store.commit('SET_LOADER_TEXT', 'Dodajanje urnika je v teku...')
                vm.$store.commit('SET_LOADER', true)
                vm.$store.dispatch('CREATE_TURNSTILE_TIMELINE', {
                    application_uuid: process.env.VUE_APP_UUID,
                    timeline_type: vm.selected_timeline_type.id,
                    selected_device: vm.selected_device.id,
                    selected_days: (vm.selected_days.length > 0 ) ? vm.selected_days.map(item => item.id) : vm.selected_days,
                    restriction_start_date: vm.restriction_start_date,
                    restriction_end_date: vm.restriction_end_date,
                    restriction_start_time: vm.restriction_start_time,
                    restriction_end_time: vm.restriction_end_time,
                    note: vm.note,
                    active: (vm.timeline_active == true || vm.timeline_active == 1 ) ? 1 : 0
                })
                .then(response => {
                    //window.console.log(response)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarText', "Urnik je bil uspešno vnesen v sistem.")
                    vm.$store.commit('snackbarMode', '')
                    vm.$store.commit('snackbarTimeout', 7000)
                    vm.$store.commit('snackbarDisplay', true)

                    vm.clearForm()
                    vm.backendMessages.push('Urnik je bil uspešno vnesen v sistem.');

                })
                .catch(error => {
                    console.log('CREATE_TURNSTILE_TIMELINE error')
                    console.log(error)
                    vm.$store.commit('snackbarDisplay', false)
                    vm.$store.commit('snackbarMode', 'multi-line')
                    vm.$store.commit('snackbarText', 'Pri vnašanju urnika v sistem je prišlo do napake. Prosimo poskusite ponovno.')
                    vm.$store.commit('snackbarDisplay', true)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            setTimeout(() => {
                                vm.$vuetify.goTo('#form_card', {
                                    duration: 1000,
                                    offset: 0,
                                    easing: 'easeInOutCubic'
                                })
                            }, 200)
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri zapisovanju urnika je prišlo do napake. Poskusite ponovno.')
                        setTimeout(() => {
                            vm.$vuetify.goTo('#form_card', {
                                duration: 1000,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }, 200)
                    }

                })
                .finally(() => {
                    setTimeout(() =>{
                        vm.loading = false;
                        vm.$store.commit('SET_PROGRESS', false)
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                        vm.search()
                    },600)
                })
            } 
            else
            {
                setTimeout(() => {
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }, 200)
            } 
        },

        clearForm() {
            this.$refs.form.resetValidation();
            this.setDefaultFormValues()
            this.clearBackendMessages()
        },

        setDefaultFormValues() {
            this.restriction_start_date = null
            this.restriction_end_date = null
            this.restriction_start_time = null;
            this.restriction_end_time = null;
            this.selected_days = []
            this.selected_device = null
            this.selected_timeline_type = null
            this.timeline_active = true
            this.note = ""

            /*if(this.userTypes.length > 0) {
                this.user_type = this.userTypes[0].uuid
            }*/
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        checkApplicationPermissions(requiredPermissions) {
            return checkPermissions(requiredPermissions, getUserApplicationPermissions())
        },

        startDateMenuSave(value) {
            this.$refs.start_date_menu.save(value)
            this.start_date_menu = false
        },

        endDateMenuSave(value) {
            this.$refs.end_date_menu.save(value)
            this.end_date_menu = false
        },

        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARKING_GATES_PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
                this.devices = response.data.turnstiles
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        search() {
            EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
        },
    },

    created() {
        let vm = this
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
        this.currentDate = getCurrentDate()
        this.dataTableSettings.endpoint = 'services/turnstiles/timelines/' + process.env.VUE_APP_UUID + "/data"

        this.card1 = this.$store.getters.appSettings.turnstile_timeline.filter_results_open

        let ipp = getAppSettingsFromLocalStorage({
            setting: "dt_turnstile_timeline",
            key: "items_per_page"
        })

        if(ipp !== null) {
            this.dataTableSettings.rowsPerPage = ipp
        }

        /*
        if(isMobileDevice(this)) {
            this.card1 = false
        }
        */

        this.setParametersData()
        this.restriction_start_date = getCurrentDate()
        this.restriction_end_date = getCurrentDate()
        this.restriction_start_date = null
        this.restriction_end_date = null
        this.restriction_start_time = "00:01"
        this.restriction_end_time = getCurrentTime()
        //this.end_time = "23:59";

        //window.console.log(this.dataTableSettings)

        EventBus.$on('REFRESH_TURNSTILE_TIMELINES_DATA_TABLE', (data) => {
            vm.search()
        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off(this.dataTableSettings.id)
        EventBus.$off(this.dataTableSettings.eventBus.refreshDataTable)
        EventBus.$off(this.dataTableSettings.eventBus.search)
        EventBus.$off("REFRESH_TURNSTILE_TIMELINES_DATA_TABLE")
    },

    destroyed() {

    }
}

</script>